/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/formstone@1.4.22/dist/js/core.js
 * - /npm/formstone@1.4.22/dist/js/checkbox.js
 * - /npm/formstone@1.4.22/dist/js/dropdown.js
 * - /npm/formstone@1.4.22/dist/js/number.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
